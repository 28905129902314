import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import upch from "@images/upch.png";
import iecs from "@images/iecs.png";
import diagrama from "@images/organigrama.png";

const IndexPage = () => {

  const changingContentArray = [
    <p>
      <b>Directors Comitee</b> <br/> On charge of giving strategic direction for acomplishing the goals of the LAC Network. In this way, they will direct the actions of empowering  activities, training of the LAC QuEST Network and will provide oritentation about research on health quality.
      <br />
      Moreover, you will organize priorities of the program and work areas, you will join efforts with other global health networks, research programs, innovation and training in Perú, Argentina and other countries of LAC.
      <br/>
      
    </p>,
    <p>
      <b>Technical Assesment Comitee (CAT):</b> <br/> On charge of assesing the research tracks and activities for the empowering of the network. Also, they will give assesment to the network in aspects that require skill and more resources.
      <br/>
      <Link to={"/acerca/estructura/cat"}>See more</Link>
    </p>,


    <p>
      <b> Strategic group of regional policies(GEPR):</b>  <br/> It will be an organ integrated by regional leaders and members of international organizations. Their goal will make easy  and guide the translation of policies and the diffusio of new findings for the research, as well as the search of opportunities of founding.
      <br/>
      <Link to={"/acerca/estructura/gepr"}>See more</Link>
    </p>,
    <p>
      <b>Core Centers:</b> <br/>They are centers of research for founding of the network and the members of Global Quest. Besides, they belong to the intitutions of main researchers. This will be the Clinic and Sanitarian Effectiveness Institute (IECS) and the Peruvian University Cayeatano Heredia with the support of the Public Health and Administration Faculty.<br/>
      <ImageContainer>
        <img src={upch} alt={upch} />
        <img src={iecs} alt={iecs} />
      </ImageContainer>
      <br/>
      <Link to={"/acerca/estructura/centroscore"}>See more</Link>
    </p>,
    <p>
      <b>Affiliated centers:</b> <br/> They are group of research or institutions that work in the LAC Region or in the Health Quality area. The affiliated  will also participate in the activities of Quest LAC like research, webinars, training skills. Also, they will contribute to debates and forums, and they will be links to policies with govenrment officials and leaders. And colleagues.
      <br/>

    </p>
  ]

  const [content, setcontent] = useState(-1)

  return (
    <TemplatePage1
      title={"QuEST LAC: Estructura"}
      showtitle={false}
      content={(
        <Wrapper >
          <Content>
            <MapImageContainer>
              
              <img src={diagrama} alt={diagrama} />
        
            </MapImageContainer>
            <MapZone
              width={"51%"}
              height={"20%"}
              left={"28%"}
              top={"4%"}
              onClick={()=>{setcontent(0)}}
            />
            <MapZone
              width={"27%"}
              height={"11%"}
              left={"15%"}
              top={"29%"}
              onClick={()=>{setcontent(1)}}
            />
            <MapZone
              width={"27%"}
              height={"11%"}
              left={"64%"}
              top={"29%"}
              onClick={()=>{setcontent(2)}}
            />
            <MapZone
              width={"57%"}
              height={"15%"}
              left={"25%"}
              top={"45%"}
              onClick={()=>{setcontent(3)}}
            />
            <MapZone
              width={"98%"}
              height={"30%"}
              left={"2%"}
              top={"66%"}
              onClick={()=>{setcontent(4)}}
            />
            {
              content>-1
              ?(
                <ChangingZone>
                {changingContentArray[content]}
                <span onClick={()=>{setcontent(-1)}}>
                Cerrar
                </span>
            </ChangingZone>
              )
              :null
            }
          </Content> 
          <TextPart>
            <h1>Structure</h1>
            In order to know more about everyone, click on different block:
          </TextPart>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage;

const TextPart = styled.h1`
width: 60%;
min-width: 360px;
text-align: center;
padding: 5px 10px;

h1{
  color: var(--tema4);
  font-size:2.5rem;
  text-align:right;
  font-weight: bold;
}

@media only screen and (min-width:768px){
  width: auto;
  min-width: auto;
  text-align:right;
  max-width: 200px;
}
`;

const ChangingZone = styled.div`
position: absolute;
width:100%;
background-color: #000000DD;
height:100%;
top:0;
left:0;
padding: 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;

a{
  display: inline-block;
  cursor: pointer;
  padding: 3px 5px;
  color: white;
  margin: 10px auto;
  width: 180px;
  background-color: var(--color2);
}

@media only screen and (min-width:1024px){
  font-size: 14px;
line-height: 28px;
}

font-size: 12px;
line-height: 18px;

color: white;

p{
  max-width: 360px;
}

b{
font-weight: bold;
}

img{
  margin: 10px auto;
  max-width: 230px;
}

span{
  display: inline-block;
  cursor: pointer;
  padding:5px;
  color: white;
  background-color: var(--tema4);
}

a{
  display: inline-block;
  cursor: pointer;
  padding: 3px 5px;
  color: white;
  margin: 10px auto;
  width: 180px;
  background-color: var(--color2);
}

animation: appear 1s;

@keyframes appear{
0%{
  opacity: 0;
  transform: scale(0);
}
100%{
  opacity: 1;
  transform: scale(1);
}

}

`;

const Wrapper = styled.div`
text-align: justify;
  padding-top: 1rem;
  display:  flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1024px){
    flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  }

`;

const MapZone = styled.div`
  //background-color: red;
  position: absolute;
  width: ${props=>props.width?props.width:0};
  height:  ${props=>props.height?props.height:0};
  left: ${props=>props.left?props.left:0};
  top:  ${props=>props.top?props.top:0};
`;

const Content = styled.div`
position: relative;
padding-top: 1rem;
padding-bottom: 1rem;
display: flex;
flex-direction: column;
align-items: center;
width: 90%;
min-width: 360px;
`;


const MapImageContainer = styled.div`
min-width: 360px;
max-width: 100%;
display:flex;
flex-direction: column;
text-align: center;
img{
    height: auto;
    width: 100%;
    margin: auto;
}
span{
    font-size: 0.7rem;
    line-height: 0.8rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

`;

const ImageContainer = styled.div`
width:75%;
margin: auto;
display: flex;

flex-direction: column;

  img{
    width: 90%;
  }


`;




